<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="tmc-dialog"
    width="45vw"
  >
    <div style="height: 65vh">
      <div id="logo">
        <el-image :src="require(`@/assets/img/logo_black.png`)" fit="contain" style="height: 100%"></el-image>
      </div>
      <div id="tabs">
        <el-tabs v-model="type">
          <el-tab-pane label="用户服务协议" name="1">
            <iframe src="/tos/UserServiceAgreement.html" frameborder="0" height="100%" width="100%"></iframe>
          </el-tab-pane>
          <el-tab-pane label="隐私政策" name="2">
            <iframe src="/tos/PrivacyPolicy.html" frameborder="0" height="100%" width="100%"></iframe>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="refuse">我拒绝</el-button>
      <el-button type="primary" @click="agree" :disabled="countDown > 0" style="width: 84.24px">
        {{ countDown > 0 ? countDown + ' s' : '我接受' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TosDialog',
  data() {
    return {
      dialogVisible: false,
      type: '1',
      countDown: 5
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.startCountDown()
    },
    agree() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/agree'),
        method: 'POST',
        data: this.$http.adornData()
      }).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false
          this.$emit('agreeTos')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    refuse() {
      this.dialogVisible = false
      this.$cookie.delete('token')
      this.$router.replace('/login')
      this.$message({
        type: 'success',
        message: '退出成功'
      })
    },
    startCountDown() {
      setTimeout(() => {
        this.countDown--
        if (this.countDown > 0) {
          this.startCountDown()
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 30px 20px;
}
#logo {
  height: 31px;
  margin-bottom: 10px;
}
::v-deep .el-tabs__content {
  height: calc(65vh - 75px);
  overflow: auto;
}
.el-tab-pane {
  height: 99%;
}
</style>

<template>
  <el-dialog title="账号管理" :visible.sync="dialogVisible" custom-class="tmc-dialog" top="30vh" width="520px">
    <el-descriptions :column="1" :colon="false" labelClassName="user-info-label" contentClassName="user-info-content">
      <el-descriptions-item label="用户名">{{ dataForm.username }}</el-descriptions-item>
      <el-descriptions-item label="手机号">
        <span :style="{ color: dataForm.phoneChange ? '' : '#73767a' }">{{
          (dataForm.validPhone ? dataForm.phoneChange : dataForm.phone) || '未绑定，绑定后可帮助您找回账号等'
        }}</span>
        <el-button v-if="dataForm.phoneChange" type="primary" @click="addOrUpdate(dataForm.emailChange ? 5 : 4)">更改</el-button>
        <el-button v-else type="primary" @click="addOrUpdate(3)">绑定</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="邮箱地址">
        <span :style="{ color: dataForm.emailChange ? '' : '#73767a' }">{{ dataForm.emailChange || '未绑定，绑定后可帮助您找回账号等' }}</span>
        <el-button v-if="dataForm.emailChange" type="primary" @click="addOrUpdate(2)">更改</el-button>
        <el-button v-else type="primary" @click="addOrUpdate(1)">绑定</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="登录密码">
        <span style="color: #73767a">登录密码已设置</span>
        <el-button type="primary" @click="addOrUpdate(dataForm.emailChange && dataForm.phoneChange ? 7 : dataForm.emailChange ? 8 : 9)"
          >更改</el-button
        >
      </el-descriptions-item>
      <el-descriptions-item label="账号注销" v-if="!isAdmin">
        <span style="color: #73767a">永久注销账号，注销后所有数据将无法找回</span>
        <el-button @click="addOrUpdate(10)">注销</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <user-info-add-or-update ref="userInfoAddOrUpdate" v-if="addOrUpdateVisible" @nextStep="getUSerInfo"></user-info-add-or-update>
  </el-dialog>
</template>

<script>
import UserInfoAddOrUpdate from '@/views/UserInfoAddOrUpdate.vue'
import { regPhone } from '@/utils'
export default {
  components: { UserInfoAddOrUpdate },
  name: 'UserInfo',
  data() {
    return {
      dialogVisible: false,
      dataForm: {},
      addOrUpdateVisible: false,
      isAdmin: true
    }
  },
  methods: {
    init() {
      this.getUSerInfo()
    },
    // 更新信息
    addOrUpdate(type) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.userInfoAddOrUpdate.init({ type, data: this.dataForm })
      })
    },
    // 获取用户信息
    getUSerInfo() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/current'),
        method: 'GET',
        params: this.$http.adornParams()
      }).then((res) => {
        if (res.code === 0) {
          const { loginName, phonenumber, email, admin } = res.data || {}
          this.isAdmin = admin
          this.dataForm = {
            email,
            emailChange: email ? email.substr(0, 3) + '****@' + email.split('@')[email.split('@').length - 1] : '',
            username: loginName,
            phone: phonenumber,
            phoneChange: phonenumber ? phonenumber.substr(0, 3) + '****' + phonenumber.substr(7) : '',
            validPhone: regPhone(phonenumber)
          }
          this.dialogVisible = true
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.user-info-label {
  height: 40.21px;
  line-height: 40.21px;
  width: 68px;
  display: block !important;
  text-align: right;
}
.user-info-content {
  height: 40.21px;
  line-height: 40.21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>

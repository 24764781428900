export default [
  {
    name: '首页',
    icon: 'shouye'
  },
  {
    name: '设备管理',
    icon: 'shebeiguanli'
  },
  {
    name: '实时预览',
    icon: 'shishiyulan'
  },
  {
    name: '设备统计',
    icon: 'shebeitongji'
  },
  {
    name: '产品信息',
    icon: 'chanpinxinxi'
  },
  {
    name: '版本固件发布',
    icon: 'banbengujianfabu'
  },
  {
    name: '用户管理',
    icon: 'user'
  },
  {
    name: '回收站',
    icon: 'recycle'
  }
]

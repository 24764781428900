<template>
  <div id="app" v-if="showUpload" :style="{ height: appStatus === 1 ? '5rem' : '30vh' }">
    <div id="upload-top">
      <div id="upload-title">上传任务</div>
      <div id="app-btn">
        <div v-bind:title="'最小化'" @click="appStatus = 1" v-if="appStatus !== 1">
          <el-image style="height: 15px" :src="require('@/assets/img/globalUpload/zoom_out.png')" fit="contain"> </el-image>
        </div>
        <div v-bind:title="'最小化'" @click="appStatus = 2" v-if="appStatus !== 2">
          <el-image style="height: 15px" :src="require('@/assets/img/globalUpload/zoom_in.png')" fit="contain"> </el-image>
        </div>
        <div v-bind:title="'关闭'" @click="handleClose()">
          <el-image style="height: 15px; margin-left: 5px" :src="require('@/assets/img/globalUpload/close.png')" fit="contain"> </el-image>
        </div>
      </div>
    </div>
    <div id="upload-result">{{ uploadResult }}</div>

    <div id="upload-table">
      <div id="table-title" v-if="isOta">
        <div class="title-class">PN码</div>
        <div class="title-class">设备型号</div>
        <div class="title-class">产品系列</div>
        <div class="title-class">状态</div>
        <div class="title-class">操作</div>
      </div>
      <div id="table-title" v-else>
        <div class="title-class">文件名称</div>
        <div class="title-class">大小</div>
        <div class="title-class">路径</div>
        <div class="title-class">状态</div>
        <div class="title-class">操作</div>
      </div>
      <div id="table-content">
        <div v-for="(item, index) in uploadObjList" :key="item.uid" class="upload-out-div">
          <div style="display: flex; width: 60%" v-if="isOta">
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip
                v-if="item.params.pnNames && item.params.pnNames.length > 7"
                effect="dark"
                :content="item.params.pnNames"
                placement="top-start"
              >
                <span> {{ item.params.pnNames | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ item.params.pnNames }}</div>
            </div>
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip
                v-if="item.params.modelNames && item.params.modelNames.length > 7"
                effect="dark"
                :content="item.params.modelNames"
                placement="top-start"
              >
                <span> {{ item.params.modelNames | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ item.params.modelNames }}</div>
            </div>
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip v-if="item.params.suit && item.params.suit.length > 7" effect="dark" :content="item.params.suit" placement="top-start">
                <span> {{ item.params.suit | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ item.params.suit }}</div>
            </div>
          </div>
          <div style="display: flex; width: 60%" v-else>
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip v-if="item.file.name && item.file.name.length > 7" effect="dark" :content="item.file.name" placement="top-start">
                <span> {{ item.file.name | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ item.file.name }}</div>
            </div>
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip
                v-if="fileSize(item.file.size) && fileSize(item.file.size).length > 7"
                effect="dark"
                :content="fileSize(item.file.size)"
                placement="top-start"
              >
                <span> {{ fileSize(item.file.size) | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ fileSize(item.file.size) }}</div>
            </div>
            <div class="upload-in-div" style="width: 33.3%">
              <el-tooltip v-if="item.params.path && item.params.path.length > 7" effect="dark" :content="item.params.path" placement="top-start">
                <span> {{ item.params.path | ellipsis(7) }} </span>
              </el-tooltip>
              <div v-else>{{ item.params.path }}</div>
            </div>
          </div>

          <div class="upload-in-div">
            <span v-if="item.status === 1">{{ item.percent.toFixed(2) + '% (' + item.speed + ') ' }}</span>
            <span v-else-if="item.status === 3" style="color: #10b08a">上传成功</span>
            <span v-else-if="item.status === -2" style="color: #e82037">上传失败</span>
            <span v-else>{{ item.status | statusTextFilter }}</span>
          </div>

          <div class="upload-in-div task-btn">
            <div v-bind:title="'开始'" v-if="item.status === 2" @click="handleClickBtn(index, 1)">
              <el-image style="height: 24px" :src="require('@/assets/img/globalUpload/task_play.png')" fit="contain"> </el-image>
            </div>
            <div v-bind:title="'暂停'" v-if="[0, 1].indexOf(item.status) !== -1" @click="handleClickBtn(index, 2)">
              <el-image style="height: 24px" :src="require('@/assets/img/globalUpload/task_pause.png')" fit="contain"> </el-image>
            </div>
            <div v-bind:title="'重试'" v-if="[-2, -1].indexOf(item.status) !== -1" @click="handleClickBtn(index, 1)">
              <el-image style="height: 24px" :src="require('@/assets/img/globalUpload/task_refresh.png')" fit="contain"> </el-image>
            </div>
            <div v-bind:title="'取消'" v-if="[0, 1, 2].indexOf(item.status) !== -1" @click="handleClickBtn(index, -1)">
              <el-image style="height: 24px" :src="require('@/assets/img/globalUpload/task_close.png')" fit="contain"> </el-image>
            </div>
          </div>
          <span class="progress-span" :style="progressStyle(item, index)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5'
export default {
  name: 'GlobalUpload',
  filters: {
    statusTextFilter(val) {
      switch (val) {
        case -2:
          return '上传失败'
        case -1:
          return '已取消'
        case 0:
          return '排队中'
        // case 1:
        //   return '上传中'
        case 2:
          return '暂停'
        case 3:
          return '上传成功'
        default:
          return '上传失败'
      }
    }
  },
  beforeDestroy() {
    this.resetData()
  },
  props: {
    showUpload: {
      type: Boolean,
      default: false
    },
    isOta: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chunkSize: 5242880, // 5M
      appStatus: 2, //  1-最小化    2-最大化
      uploading: false, // 当时是否正在上传
      uploadIndex: 0, // 当前正在上传的index
      uploadObjList: [], // 上传主体列表
      successNum: 0,
      failedFlag: false
    }
  },
  computed: {
    // vuex列表
    uploadList() {
      const uploadList = []
      uploadList.push(...this.$store.state.uploadList)
      return uploadList
    },
    // 上传结果
    uploadResult() {
      // 统计结果
      const allNum = this.uploadObjList.length
      let successNum = 0,
        pauseNum = 0,
        cancelNum = 0,
        failedNum = 0
      this.uploadObjList.forEach((item) => {
        switch (item.status) {
          case -2:
            failedNum++
            break

          case -1:
            cancelNum++
            break

          case 2:
            pauseNum++
            break

          case 3:
            successNum++
            break

          default:
            break
        }
      })

      let result = ''
      if (this.uploading) {
        // 有任务时，若有在上传的，则显示“正在上传(1/2)”，分子为成功任务数+1，分母为当前表内任务总数
        result = `正在上传(${successNum + 1}/${allNum})`
      } else {
        // 能上传的任务都处理完成时，若有失败任务则显示“有n个任务上传失败。”若有暂停或取消任务时显示“有n个任务被暂停或取消 。”若上传均成功则显示“上传完成”，描述顺序即为显示优先级
        if (successNum === allNum) {
          result = '上传完成'
        } else {
          result =
            (failedNum > 0 ? `有${failedNum}个任务上传失败` : '') +
            (failedNum > 0 && pauseNum + cancelNum > 0 ? '，' : '') +
            (pauseNum + cancelNum > 0 ? `有${pauseNum + cancelNum}个任务被暂停或取消` : '')
        }
      }

      return result
    },
    // 当前是否有未完成的任务
    isFinish() {
      return !(
        this.uploading ||
        this.uploadObjList.findIndex((obj) => {
          return [0, 1, 2].indexOf(obj.status) !== -1
        }) !== -1
      )
    },
    // 进度条
    progressStyle() {
      return (item, index) => {
        return `width: ${this.uploadIndex === index && this.uploading ? item.percent + '%' : 0};`
      }
    },
    // 文件大小
    fileSize() {
      return (size) => {
        let num = size
        let unit = 'B'
        // KB
        if (num >= 1024) {
          num = Math.ceil(num / 1024)
          unit = 'KB'
          // MB
          if (num >= 1024) {
            num = Math.ceil((num * 10) / 1024) / 10
            if (num.toString().split('.')[1] === '0') {
              num = num.toString().split('.')[0]
            }
            unit = 'MB'
            // GB
            if (num >= 1024) {
              num = Math.ceil((num * 10) / 1024) / 10
              if (num.toString().split('.')[1] === '0') {
                num = num.toString().split('.')[0]
              }
              unit = 'GB'
            }
          }
        }

        return num + unit
      }
    }
  },
  watch: {
    uploadList(newVal, oldVal) {
      // 获取新增的上传任务
      const oldUidList = oldVal.map((item) => {
        return item.file.uid
      })
      const addList = newVal.filter((item) => {
        return oldUidList.indexOf(item.file.uid) === -1
      })

      if (addList.length > 0) {
        // 限制任务数
        if (this.isOta) {
          if (this.uploadObjList.length - this.successNum > 9) {
            this.$confirm(
              `<div >
                <div class="tmc_confirm_icon"></div>
                <div class="tmc_confirm_title">上传任务数量已达上限</div> 
                <div class="tmc_confirm_content">上传任务数量上限为10个，请等待旧任务结束后再添加新任务</div> 
              </div>`,
              {
                confirmButtonText: '确定',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                center: true,
                customClass: 'tmc_confirm'
              }
            ).then(() => {})
            return
          }
        } else {
          if (this.uploadObjList.length - this.successNum > 99) {
            this.$confirm(
              `<div >
                <div class="tmc_confirm_icon"></div>
                <div class="tmc_confirm_title">上传任务数量已达上限</div> 
                <div class="tmc_confirm_content">上传任务数量上限为100个，请等待旧任务结束后再添加新任务</div> 
              </div>`,
              {
                confirmButtonText: '确定',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                center: true,
                customClass: 'tmc_confirm'
              }
            ).then(() => {})
            return
          }
        }

        // 显示上传组件
        if (!this.showUpload) {
          this.$emit('update:showUpload', true)
        }
        // 构造任务
        if (this.isOta) {
          this.createTask(addList[0])
        } else {
          addList.forEach((add) => {
            this.createTask(add)
          })
        }
      }
    },
    // 关闭时清空数据
    showUpload(val) {
      if (!val) {
        this.resetData()
      }
    }
  },
  methods: {
    // 创建任务
    createTask(uploadObj) {
      // 处理参数
      const {
        // 文件
        file,
        // 业务参数
        params,
        // 其他参数
        replaceId
      } = uploadObj

      const chunkObj = {
        // 状态参数
        file,
        uid: file.uid,
        fileName: file.name,
        chunkNames: [], // 切片名称
        status: 0, // 上传状态
        percent: 0, // 上传百分比
        percentCount: 0, // 单片所占百分比
        curChunk: 0, // 当前切片数
        chunkListLength: Math.ceil(file.size / this.chunkSize), // 总切片数
        suffix: /\.([0-9A-z]+)$/.exec(file.name)[0], // 文件后缀
        md5: new SparkMD5.ArrayBuffer(), // 根据文件内容生产md5
        speed: '0',
        oldTimeStamp: 0,
        oldLoaded: 0, // B
        // 业务参数
        params
      }

      // 判断替换或添加
      if (replaceId) {
        for (let i = 0; i < this.uploadObjList.length; i++) {
          if (this.uploadObjList[i].uid === replaceId) {
            this.$set(this.uploadObjList, i, chunkObj)
            break
          }
        }
      } else {
        this.uploadObjList.push(chunkObj)
      }

      // 统计各个状态的任务
      this.statTask()

      // 调用上传
      this.sendRequest()
    },
    // 统计各个状态的任务
    statTask() {
      if (this.isOta) {
        // 统计各个pn的任务状态
        // 正在上传或等待上传的uid pns {pns, uid}
        const pnList = {}
        // 已取消或上传失败的uid pns {pns, uid}
        const failedPnList = {}
        this.uploadObjList.forEach((obj) => {
          const pns = obj.params.pns || []
          // 正在上传或等待上传的
          if ([0, 1, 2].indexOf(obj.status) !== -1) {
            pns.forEach((pn) => {
              pnList[pn] = obj.uid
            })
          }
          // 已取消或上传失败的
          if ([-2, -1].indexOf(obj.status) !== -1) {
            pns.forEach((pn) => {
              failedPnList[pn] = obj.uid
            })
          }
        })
        this.$store.commit('updateUploadingPnList', pnList)
        this.$store.commit('updateFailedPnList', failedPnList)
      } else {
        // 统计除成功以外的文件
        const unsuccessList = []
        this.uploadObjList.forEach((obj) => {
          if (obj.status !== 3) {
            unsuccessList.push(obj.file.name)
          }
        })
        this.$store.commit('updateUnsucessFileList', unsuccessList)
      }
    },
    // 任务点击
    handleClickBtn(index, val) {
      const chunkObj = this.uploadObjList[index]
      chunkObj.status = val

      // 统计各个状态的pn
      this.statTask()

      if (val === 1) {
        // 开始
        // 有任务则排队
        if (this.uploading) {
          chunkObj.status = 0
        } else {
          this.sendRequest()
        }
      } else {
        // 若是正在执行的任务就开始下一个任务
        if (index === this.uploadIndex) {
          this.uploading = false
          this.sendRequest()
        }
      }
    },
    // 发送请求
    sendRequest() {
      if (!this.uploading) {
        for (let i = 0; i < this.uploadObjList.length; i++) {
          const chunkObj = this.uploadObjList[i],
            file = chunkObj.file
          // 开始第一个排队的
          if ([0, 1].indexOf(chunkObj.status) !== -1) {
            // 改状态
            this.uploading = true
            this.uploadIndex = i
            chunkObj.status = 1

            // 失败方法
            const failed = () => {
              chunkObj.status = -2
              this.uploading = false

              // 统计各个状态的pn
              this.statTask()

              this.sendRequest()
            }

            // 完成方法
            const complete = () => {
              // 调用请求
              const { params, fileName, chunkNames } = chunkObj
              const { suit, modelAndPn, pns, otaVersion, comment, remark, type } = params

              this.$http({
                url: this.$http.adornUrl(`${this.isOta ? '/api/v1/ota/complete' : '/api/v1/files/special/complete'}`),
                method: 'post',
                data: this.$http.adornData({
                  suit,
                  modelAndPn,
                  fileName,
                  chunkNames,
                  type
                })
              })
                .then((res) => {
                  if (res.code === 0) {
                    const data = res.data
                    if (this.isOta) {
                      // 创建业务记录
                      this.$http({
                        url: this.$http.adornUrl('/api/v1/ota/save'),
                        method: 'post',
                        data: this.$http.adornData({
                          productionIds: pns,
                          otaVersion,
                          comment,
                          remark,
                          otaPath: data
                        })
                      })
                        .then((res) => {
                          if (res.code === 0) {
                            // 统计成功
                            console.log('complete-success')
                            chunkObj.status = 3
                            this.successNum++
                            this.uploading = false
                            this.$store.commit('addRefresh')
                            this.$store.commit('updateCapacity')

                            // 统计各个状态的pn
                            this.statTask()

                            this.sendRequest()
                          } else {
                            this.$message.error(res.msg)
                            console.log('save-failed')
                            failed()
                          }
                        })
                        .catch(() => {
                          console.log('save-failed')
                          failed()
                        })
                    } else {
                      // 统计成功
                      console.log('complete-success')
                      chunkObj.status = 3
                      this.successNum++
                      this.uploading = false
                      this.$store.commit('addRefresh')
                      this.$store.commit('updateCapacity')

                      // 统计各个状态的pn
                      this.statTask()

                      this.sendRequest()
                    }
                  } else {
                    this.$message.error(res.msg)
                    console.log('complete-failed')
                    failed()
                  }
                })
                .catch(() => {
                  console.log('complete-failed')
                  failed()
                })
            }

            // 边切分边上传
            let chunk = undefined
            const fread = new FileReader()
            fread.onload = (e) => {
              const ceilBuffer = e.target.result
              const chunkName = `${chunkObj.uid}_${chunkObj.curChunk}${chunkObj.suffix}`
              // 发送请求
              const form = new FormData()
              form.append('chunk', chunk)
              form.append('fileName', chunkName)

              this.$http({
                url: this.$http.adornUrl(`${this.isOta ? '/api/v1/ota/uploadFiles' : '/api/v1/files/special/uploadFiles'}?t=${new Date().getTime()}`),
                method: 'post',
                timeout: 1000 * 60 * 3,
                Headers: {
                  'Content-Type': 'multipart/form-data'
                },
                data: form,
                onUploadProgress: (progress) => {
                  const { oldTimeStamp, oldLoaded } = chunkObj
                  const { timeStamp, loaded, total } = progress
                  const speed = this.fileSize((loaded - oldLoaded) / ((timeStamp - oldTimeStamp) / 1000))
                  chunkObj.speed = (speed < 0 ? 0 : speed) + '/s'
                  chunkObj.oldTimeStamp = timeStamp
                  chunkObj.oldLoaded = loaded === total ? 0 : loaded
                }
              })
                .then((res) => {
                  if (res.code === 0) {
                    if (chunkObj.percentCount === 0) {
                      chunkObj.percentCount = 100 / chunkObj.chunkListLength
                    }
                    chunkObj.percent += chunkObj.percentCount
                    chunkObj.md5.append(ceilBuffer)
                    chunkObj.curChunk++
                    chunkObj.chunkNames.push(chunkName)

                    if (chunkObj.curChunk < chunkObj.chunkListLength) {
                      uploadNext()
                    } else {
                      console.log('finish upload')
                      chunkObj.md5.end()
                      complete()
                    }
                  } else {
                    this.$message.error(res.msg)
                    console.log('send failed', chunkObj.curChunk + 1)
                    failed()
                  }
                })
                .catch(() => {
                  console.log('send failed', chunkObj.curChunk + 1)
                  failed()
                })
            }
            fread.onerror = (err) => {
              console.log('转换文件格式发生错误', err)
              failed()
            }

            // 下一个
            const uploadNext = () => {
              if (chunkObj.status !== 1) return

              const start = chunkObj.curChunk * this.chunkSize,
                end = start + this.chunkSize >= file.size ? file.size : start + this.chunkSize

              chunk = file.slice(start, end) // 用Blob.slice()切割
              fread.readAsArrayBuffer(chunk)
            }

            console.log('start upload')
            uploadNext()
          }

          if (this.uploading) {
            break
          }
        }
      }
    },
    // 关闭
    handleClose() {
      if (!this.isFinish) {
        this.$confirm(
          `<div >
            <div class="tmc_confirm_icon"></div>
            <div class="tmc_confirm_title">放弃上传</div>
            <div class="tmc_confirm_content">列表中有未完成的上传任务，确定放弃上传吗？</div> 
          </div>`,
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            center: true,
            customClass: 'tmc_confirm'
          }
        )
          .then(() => {
            this.$emit('update:showUpload', false)
          })
          .catch(() => {})
      } else {
        this.$emit('update:showUpload', false)
      }
    },
    // 重置数据
    resetData() {
      this.appStatus = 2
      this.uploading = false
      this.uploadIndex = 0
      this.uploadObjList = []
      this.successNum = 0
      this.$store.commit('clearUpload')
      this.$store.commit('updateUploadingPnList', {})
      this.$store.commit('updateFailedPnList', {})
      this.$store.commit('updateUnsucessFileList', [])
    }
  }
}
</script>

<style lang="scss" scoped>
$lineHeight: 2.5rem;
$paddingLeft: 20px;
#app {
  // 上传窗口
  overflow: hidden;
  font-size: 0.875rem;
  z-index: 999;
  background: #fff;
  position: absolute;
  right: 18px;
  bottom: 18px;
  width: 30vw;
  border-radius: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #494d52;
  border: 0.5px solid #bbbbbb;
  #upload-top {
    display: flex;
    display: -webkit-flex; /* Safari */
    justify-content: space-between;
    background: #d3d5d7;
    height: $lineHeight;
    line-height: $lineHeight;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    #upload-title {
      padding-left: $paddingLeft;
    }
    #app-btn {
      display: flex;
      align-items: center;
      padding-right: $paddingLeft;
      cursor: pointer;
      div {
        vertical-align: middle;
      }
      div:hover {
        transform: translateY(-1px);
      }
    }
  }

  #upload-result {
    background: #f4f4f4;
    color: #5053dd;
    height: $lineHeight;
    line-height: $lineHeight;
    padding-left: $paddingLeft;
  }

  #upload-table {
    height: calc(100% - 4rem);
    color: #71757a;
    #table-title {
      height: 3rem;
      display: flex;
      align-items: center;
      overflow-y: scroll;
      border-bottom: 0.1px solid #dbdbdb;
      .title-class {
        width: 20%;
        font-weight: 500;
        text-align: center;
      }
    }
    #table-content {
      height: calc(100% - 4rem);
      overflow-y: scroll;
      overflow-x: auto;
      .upload-out-div {
        height: 3rem;
        display: flex;
        align-items: center;
        border-bottom: 0.1px solid #dbdbdb;
        position: relative;
        .upload-in-div {
          width: 20%;
          text-align: center;
        }
        .task-btn {
          display: flex;
          justify-content: center;
          cursor: pointer;
          div + div {
            margin-left: 10px;
          }
          div:hover {
            transform: translateY(-1px);
          }
        }
        .progress-span {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          z-index: -1;
          background: #87daff;
        }
      }
    }
  }
}
</style>

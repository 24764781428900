<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="canClose"
    :show-close="canClose"
    append-to-body
    custom-class="tmc-dialog"
    top="30vh"
    width="580px"
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="70px" :status-icon="false" hide-required-asterisk>
      <el-form-item label-width="14px" v-if="type === 10">
        <div class="log-out-item-content">
          <span>账号下所有设备已删除</span>
          <div>
            <div v-if="resultLoadingList[0]" v-loading="true" style="width: 50px; height: 55px"></div>
            <div v-else>
              <el-image v-if="resultList[0]" :src="require(`@/assets/img/account-cancellation_icon_success.png`)" fit="contain"> </el-image>
              <el-image v-else :src="require(`@/assets/img/account-cancellation_icon_failure.png`)" fit="contain"> </el-image>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label-width="14px" v-if="type === 10">
        <div class="log-out-item-content">
          <span>账号下文件和视频分享已删除</span>
          <div>
            <div v-if="resultLoadingList[1]" v-loading="true" style="width: 50px; height: 55px"></div>
            <div v-else>
              <el-image v-if="resultList[1]" :src="require(`@/assets/img/account-cancellation_icon_success.png`)" fit="contain"> </el-image>
              <el-image v-else :src="require(`@/assets/img/account-cancellation_icon_failure.png`)" fit="contain"> </el-image>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label-width="14px" v-if="type === 10">
        <div class="log-out-item-content">
          <span>账号下所有数据已删除、回收站已清空</span>
          <div>
            <div v-if="resultLoadingList[2]" v-loading="true" style="width: 50px; height: 55px"></div>
            <div v-else>
              <el-image v-if="resultList[2]" :src="require(`@/assets/img/account-cancellation_icon_success.png`)" fit="contain"> </el-image>
              <el-image v-else :src="require(`@/assets/img/account-cancellation_icon_failure.png`)" fit="contain"> </el-image>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item key="email" prop="email" label="新邮箱" v-if="type === 1">
        <el-input v-model="dataForm.email" placeholder="请输入新邮箱地址"></el-input>
      </el-form-item>

      <el-form-item label-width="14px" v-if="[5, 7, 11].indexOf(this.type) !== -1">
        <el-radio v-model="nextType" :label="1">{{ '手机号' + (dataForm.validPhone ? dataForm.phoneChange : dataForm.phone) }}</el-radio>
      </el-form-item>
      <el-form-item label-width="14px" v-if="[5, 7, 11].indexOf(this.type) !== -1">
        <el-radio v-model="nextType" :label="2">{{ '邮箱' + dataForm.emailChange }}</el-radio>
      </el-form-item>

      <el-form-item label-width="14px" v-if="[2, 8, 12].indexOf(type) !== -1 || nextType === 2">
        {{ '为保证账户安全，请使用 ' + dataForm.emailChange + ' 验证' }}
      </el-form-item>

      <el-form-item key="phone" prop="phone" label="新手机号" v-if="type === 3">
        <el-input v-model="dataForm.phone" placeholder="请输入新手机号">
          <el-select v-model="dataForm.crownCode" slot="prepend" placeholder="请选择">
            <el-option value="+86"></el-option>
          </el-select>
        </el-input>
      </el-form-item>

      <el-form-item label-width="14px" v-if="[4, 9, 13].indexOf(type) !== -1 || nextType === 1">
        {{ '为保证账户安全，请使用 ' + (dataForm.validPhone ? dataForm.phoneChange : dataForm.phone) + ' 验证' }}
      </el-form-item>

      <el-form-item key="password" label="新密码" prop="password" v-if="type === 6">
        <el-input v-model="dataForm.password" :type="passwordType" placeholder="输入以修改密码" style="width: 97%">
          <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
            <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
            <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
          </div>
          ></el-input
        >
        <span style="position: absolute; right: -11px">
          <el-tooltip effect="dark" placement="top">
            <div slot="content">
              1.长度为8~14个字符<br />2.字母、数字以及特殊符号至少包含2种<br />3.不允许有空格、中文<br />
              4.区分大小写
            </div>
            <el-image :src="require(`@/assets/img/icon_info_def.png`)"> </el-image>
          </el-tooltip>
        </span>
      </el-form-item>

      <el-form-item key="comfirmPassword" label="确认密码" prop="comfirmPassword" v-if="type === 6">
        <el-input v-model="dataForm.comfirmPassword" :type="comfirmPasswordType" placeholder="输入以确认密码" style="width: 97%">
          <div slot="suffix" @click="showComfirmPassword = !showComfirmPassword" class="password-suffix">
            <i v-if="showComfirmPassword" class="el-input__icon el-icon-view"></i>
            <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
          </div>
          ></el-input
        >
      </el-form-item>

      <el-form-item key="validCode" prop="validCode" label="验证码" v-if="[6, 10, 14].indexOf(type) === -1">
        <div style="position: relative">
          <el-input v-model="dataForm.validCode" placeholder="请输入验证码"></el-input>
          <span style="position: absolute; right: 12px">
            <span v-if="btnLoading" style="color: #5053dd">{{ countDown }}</span>
            <el-button v-else @click="getValidCode" :loading="btnLoading" type="text">获取验证码</el-button>
          </span>
        </div>
      </el-form-item>

      <el-form-item v-if="[1, 3, 6].indexOf(type) !== -1" style="text-align: center" label-width="0">
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>

      <el-form-item v-else-if="[2, 4, 5, 7, 8, 9, 11, 12, 13].indexOf(type) !== -1" style="text-align: center" label-width="0">
        <el-button type="primary" @click="nextStep">下一步</el-button>
      </el-form-item>

      <el-form-item v-else-if="type === 10" style="text-align: center" label-width="0">
        <el-button type="primary" @click="nextStep" :disabled="!(resultList[0] && resultList[1] && resultList[2])">下一步</el-button>
      </el-form-item>

      <el-form-item v-if="type === 10" prop="check" style="text-align: center" class="tos-form-item" label-width="0">
        <div class="user-rule">
          <el-checkbox v-model="dataForm.check"></el-checkbox>
          <span> 同意并接受燧石技术<el-button type="text" @click="toTOS(3)">《用户注销协议》</el-button></span>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="type === 14" class="finish-log-out">
      <div>
        <el-image :src="require(`@/assets/img/result_icon_success.png`)" fit="contain"> </el-image>
      </div>
      <div style="font-size: 18px; font-weight: bold">账号已注销</div>
      <div>感谢您的支持，5秒后将自动退出，如需再次使用TI-5000服务，请重新注册账号。</div>
      <el-button style="background: rgba(30, 30, 242, 0.1)" @click="toLogin">立即退出</el-button>
    </div>
  </el-dialog>
</template>

<script>
import merge from 'lodash/merge'
export default {
  name: 'UserInfoAddOrUpdate',
  data() {
    return {
      dialogVisible: false,
      canClose: true,
      type: 0,
      title: '',
      btnLoading: false,
      countDown: 120,
      countDownIntervalId: undefined,
      isEmail: true,
      templateForm: {
        phone: '',
        email: '',
        validCode: '',
        crownCode: '+86',
        password: '',
        comfirmPassword: '',
        check: false
      },
      dataForm: {},
      dataRule: {
        validCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('邮箱不能为空'))
              } else if (
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
                  value
                )
              ) {
                callback(new Error('请输入正确的邮箱'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('手机号不能为空'))
              } else if (!/^(?:(?:\+|00)86)?1\d{10}$/g.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('密码不能为空'))
              } else if (!/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,14}$/g.test(value)) {
                callback(new Error('请设置长度为8~14个字符，字母、数字以及特殊符号至少包含2种，无空格和中文的密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        comfirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('确认密码不能为空'))
              } else if (this.dataForm.password !== value) {
                callback(new Error('两次密码输入不一致'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        check: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请勾选“同意并接受燧石技术用户注销协议”'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      },
      nextType: 0, // 验证方式 1 手机 2 邮箱
      showPassword: false,
      showComfirmPassword: false,
      changeFlag: true, // 是否监听nextType
      resultList: [], // 检测结果
      resultLoadingList: [true, true, true] // 检测加载结果
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    comfirmPasswordType() {
      return this.showComfirmPassword ? 'text' : 'password'
    }
  },
  watch: {
    nextType(val) {
      if (this.changeFlag) {
        this.isEmail = val === 2
      }
    }
  },
  methods: {
    init({ type, canClose = true, data = {} }) {
      this.type = type
      this.canClose = canClose
      this.dataForm = merge({}, this.templateForm, data)
      this.changeFlag = true
      this.nextType = 0
      this.btnLoading = false
      this.resultList = []
      this.resultLoadingList = [true, true, true]

      if (this.type === 1) {
        //  绑定邮箱
        this.title = '绑定邮箱'
        this.isEmail = true
      } else if (this.type === 2) {
        // 修改邮箱
        this.title = '修改邮箱'
        this.isEmail = true
      } else if (this.type === 3) {
        // 绑定手机号
        this.title = '绑定手机号'
        this.isEmail = false
      } else if (this.type === 4) {
        // 修改手机号 只有手机
        this.title = '修改手机号'
        this.isEmail = false
      } else if (this.type === 5) {
        // 修改手机号 有手机和邮箱
        this.title = '修改手机号'
        this.nextType = 1
      } else if (this.type === 6) {
        // 修改密码
        this.title = '修改密码'
        this.changeFlag = false
      } else if (this.type === 7) {
        // 修改密码 有手机和邮箱
        this.title = '修改密码'
        this.nextType = 1
      } else if (this.type === 8 || this.type === 9) {
        // 修改密码 8 只有邮箱 9 只有手机
        this.title = '修改密码'
        this.isEmail = this.type === 8
      } else if (this.type === 10) {
        // 注销
        this.title = '账号注销'
        this.getDeviceResult()
        this.getFileResult()
        this.getDataResult()
      } else if (this.type === 11) {
        // 注销 有手机和邮箱
        this.title = '账号注销'
        this.nextType = 1
      } else if (this.type === 12 || this.type === 13) {
        // 注销 12 只有邮箱 13 只有手机
        this.title = '账号注销'
        this.isEmail = this.type === 12
      } else if (this.type === 14) {
        // 注销完成
        this.title = ''
      }

      this.dialogVisible = true
    },
    // 注销账号
    unsubscribe() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/delete'),
        method: 'DELETE',
        params: this.$http.adornParams({
          code: this.dataForm.validCode
        })
      }).then((res) => {
        if (res.code === 0) {
          this.init({ type: 14 })
          setTimeout(() => {
            this.toLogin()
          }, 1000 * 5)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 回到登录页
    toLogin() {
      this.$cookie.delete('token')
      this.$router.replace('/login')
    },
    // 设备删除查询
    getDeviceResult() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/isDeviceUnbind'),
        method: 'GET',
        params: this.$http.adornParams()
      })
        .then((res) => {
          if (res.code === 0) {
            this.$set(this.resultList, 0, res.data === 0)
          } else {
            this.$message.error(res.msg)
          }
          this.$set(this.resultLoadingList, 0, false)
        })
        .catch(() => {
          this.$set(this.resultLoadingList, 0, false)
        })
    },
    // 文件和分享删除查询
    getFileResult() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/isFileDeleted'),
        method: 'GET',
        params: this.$http.adornParams()
      })
        .then((res) => {
          if (res.code === 0) {
            this.$set(this.resultList, 1, res.data === 0)
          } else {
            this.$message.error(res.msg)
          }
          this.$set(this.resultLoadingList, 1, false)
        })
        .catch(() => {
          this.$set(this.resultLoadingList, 1, false)
        })
    },
    // 数据和回收站删除查询
    getDataResult() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/isSpecialDeleted'),
        method: 'GET',
        params: this.$http.adornParams()
      })
        .then((res) => {
          if (res.code === 0) {
            this.$set(this.resultList, 2, res.data === 0)
          } else {
            this.$message.error(res.msg)
          }
          this.$set(this.resultLoadingList, 2, false)
        })
        .catch(() => {
          this.$set(this.resultLoadingList, 2, false)
        })
    },
    // 注销协议
    toTOS(type) {
      const { href } = this.$router.resolve({ name: 'TermsOfService', query: { type } })
      window.open(href, '_blank')
    },
    // 下一步
    nextStep() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const { email, emailChange, phone, phoneChange, validCode } = this.dataForm
          if (this.type === 10) {
            this.init({ type: emailChange && phoneChange ? 11 : emailChange ? 12 : 13, data: this.dataForm })
          } else {
            this.$http({
              url: this.$http.adornUrl('/api/v1/auth/validateCode'),
              method: 'post',
              params: this.$http.adornParams({
                email: this.isEmail ? email : undefined,
                phoneNum: !this.isEmail ? phone : undefined,
                validateCode: validCode
              })
            }).then((res) => {
              if (res.code === 0) {
                if ([11, 12, 13].indexOf(this.type) !== -1) {
                  this.unsubscribe()
                } else {
                  let data = {}
                  if ([7, 8, 9].indexOf(this.type) !== -1) {
                    data = { type: 6, data: this.dataForm }
                  } else {
                    data = { type: this.type === 2 ? 1 : 3 }
                  }
                  this.init(data)
                }
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    // 获取验证码
    getValidCode() {
      if ([2, 4, 5, 7, 8, 9, 11, 12, 13].indexOf(this.type) !== -1) {
        this.btnLoading = true
        this.getCode()
      } else {
        this.$refs.dataForm.validateField(this.isEmail ? 'email' : 'phone', (valid) => {
          if (!valid) {
            this.btnLoading = true
            // 校重
            this.$http({
              url: this.$http.adornUrl('/api/v1/auth/checkUser'),
              method: 'get',
              params: this.$http.adornParams({
                email: this.isEmail ? this.dataForm.email : undefined,
                phoneNum: !this.isEmail ? this.dataForm.phone : undefined
              })
            })
              .then((res) => {
                // 注册
                if (res.code === 0) {
                  this.getCode()
                } else {
                  this.$message.error(res.msg)
                  this.btnLoading = false
                }
              })
              .catch(() => {
                this.btnLoading = false
              })
          }
        })
      }
    },
    // 获取验证码
    getCode() {
      // 判断时间
      const startCountTime = JSON.parse(sessionStorage._startCountTime || '[]')
      for (let i = 0; i < startCountTime.length; i++) {
        if (
          (this.isEmail && startCountTime[i].account === this.dataForm.email) ||
          (!this.isEmail && startCountTime[i].account === this.dataForm.phone)
        ) {
          if ((new Date().getTime() - (startCountTime[i].sendTime || 0)) / 1000 < 120) {
            this.$message.error('您的操作太频繁了，请2分钟后重试')
            this.btnLoading = false
            return
          }
        }
      }

      this.$http({
        url: this.$http.adornUrl(`/api/v1/auth/${this.isEmail ? 'getCode' : 'getPhoneCode'}`),
        method: 'POST',
        data: this.$http.adornData({
          email: this.isEmail ? this.dataForm.email : undefined,
          phone: !this.isEmail ? this.dataForm.phone : undefined
        })
      })
        .then((res) => {
          if (res.code === 0) {
            // 倒计时
            this.countDown = 120
            this.countDownIntervalId = setInterval(this.getCountDown, 1000)
            // 记录时间
            startCountTime.push({ account: this.isEmail ? this.dataForm.email : this.dataForm.phone, sendTime: new Date().getTime() })
            sessionStorage._startCountTime = JSON.stringify(startCountTime)
            this.$message({
              message: this.isEmail ? '邮件已发送' : '短信已发送',
              type: 'success'
            })
          } else {
            this.$message.error(res.msg)
            this.btnLoading = false
          }
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    // 获取倒计时
    getCountDown() {
      this.countDown--
      if (this.countDown < 1 || this.btnLoading === false) {
        this.btnLoading = false
        clearInterval(this.countDownIntervalId)
        this.countDown = 120
      }
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const { email, validCode, crownCode, phone, password } = this.dataForm
          if (this.type === 6) {
            this.$http({
              url: this.$http.adornUrl(`/api/v1/auth/forgetPassword`),
              method: 'POST',
              data: this.$http.adornData({
                email: this.isEmail ? email : undefined,
                phoneNum: !this.isEmail ? phone : undefined,
                newPassword: password,
                validateCode: validCode,
                contact: this.isEmail ? email : phone
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    sessionStorage.needTip = false
                    this.dialogVisible = false
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$http({
              url: this.$http.adornUrl(`/api/v1/profile/${this.isEmail ? 'bindEmail' : 'bindPhone'}`),
              method: 'POST',
              params: this.$http.adornParams({
                email: this.isEmail ? email : undefined,
                crownCode: !this.isEmail ? crownCode : undefined,
                phone: !this.isEmail ? phone : undefined,
                code: validCode
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '绑定成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.dialogVisible = false
                    this.$emit('nextStep')
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.log-out-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tos-form-item {
  .user-rule {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4d4e52;
    span {
      margin-left: 4px;
    }
  }

  ::v-deep .el-form-item__error {
    margin-left: 25.5%;
    line-height: 0;
    padding-top: 0;
  }
}

// 区号选择前缀
::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-select .el-input {
  width: 80px;
  .el-form-item--feedback .el-input__validateIcon {
    display: none;
  }
}

.password-suffix {
  width: 25px;
  height: 100%;
  cursor: pointer;
}

::v-deep .el-image__inner {
  vertical-align: middle;
}

.finish-log-out {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
</style>

<template>
  <el-container style="height: 100%">
    <el-header class="header">
      <div class="header_left">
        <div>
          <img src="@/assets/img/top-menu_icon_temperature.png" width="32px" />
        </div>
        <div>TI-5000平台</div>
      </div>
      <div class="header_right">
        <img src="@/assets/img/top-menu_icon_user_def.png" />
        <el-popover ref="safePopover" placement="bottom-start" trigger="manual" width="278" v-model="popoverVisible">
          <div class="safe-popover">
            <div>
              <img slot="reference" src="@/assets/img/alert_icon_caution.png" style="vertical-align: middle; margin-top: -5px" />
              <span> 你的密码安全等级太低，建议立即修改密码。</span>
            </div>
            <div class="popover-footer">
              <el-button @click="popoverVisible = false">取消</el-button>
              <el-button type="primary" @click="toChangePassword">修改</el-button>
            </div>
          </div>
          <div slot="reference" style="margin-left: 8px">{{ dataForm.username }}</div>
        </el-popover>

        <el-popover placement="bottom" width="72" trigger="click">
          <div class="popover-content" @click="toUserInfo">账号管理</div>
          <div class="popover-content" @click="toTOS">条款与条件</div>
          <div class="popover-content" @click="signOut">退出登录</div>
          <img slot="reference" src="@/assets/img/icon_down_def.png" style="margin-right: 25px; cursor: pointer" />
        </el-popover>
        <!-- <div>简体中文</div>
        <el-popover placement="bottom" width="50" trigger="click">
          <div v-for="item in languageList" :key="item.type" class="popover-content" @click="langChange(item.type)">{{ item.label }}</div>
          <img slot="reference" src="@/assets/img/icon_down_def.png" style="cursor: pointer" />
        </el-popover> -->
      </div>
    </el-header>
    <el-container class="outContainer">
      <el-aside width="200px" class="aside">
        <div id="aside-menu">
          <el-menu router unique-opened>
            <div v-for="(item, index) in routers" :key="index">
              <div v-if="!item.hidden">
                <el-submenu :index="index.toString()" v-if="item.title !== '顶级菜单'" class="menu_parent">
                  <template slot="title">
                    <img :src="require(`@/assets/img/menuIcon/${getIcon(item.title)}.png`)" />
                    <span>{{ item.title }}</span>
                  </template>
                  <el-menu-item v-for="(child, index_c) in item.children" :key="index_c" :index="child.path" class="menu_child">
                    <div
                      class="menu-item"
                      :style="{
                        background: child.path === path ? 'linear-gradient(270deg, rgb(0, 236, 255, 0.1) 0%, rgb(30, 30, 242, 0.1) 100%)' : ''
                      }"
                      style="padding-left: 35px"
                    >
                      <img :src="require(`@/assets/img/menuIcon/${getIcon(child.title, child.path === path)}.png`)" />
                      <span>{{ child.title }}</span>
                    </div>
                  </el-menu-item>
                </el-submenu>
                <div v-else class="menu_parent">
                  <div v-for="(child, index_c) in item.children" :key="index_c">
                    <el-menu-item v-if="menuStatus(child.isAdmin) && !child.hidden" :key="index_c" :index="child.path" class="menu_child">
                      <div
                        class="menu-item"
                        :style="{
                          background: child.path === path ? 'linear-gradient(270deg, rgb(0, 236, 255, 0.1) 0%, rgb(30, 30, 242, 0.1) 100%)' : ''
                        }"
                      >
                        <img :src="require(`@/assets/img/menuIcon/${getIcon(child.title, child.path === path)}.png`)" />
                        <span>{{ child.title }}</span>
                      </div>
                    </el-menu-item>
                  </div>
                </div>
              </div>
            </div>
          </el-menu>
        </div>
        <div id="store-capacity" v-if="!isAdmin">
          <div id="capacity-title">个人空间容量</div>
          <el-progress :percentage="percentage" :color="colorMethod" :format="() => {}"></el-progress>
          <div id="capacity-text">
            <div>{{ capacity }}</div>
            <!-- <div>{{ percentage + '%' }}</div> -->
          </div>
        </div>
      </el-aside>
      <el-main style="background: #f4f4f4">
        <router-view style="background: white" />
      </el-main>
      <global-upload ref="globalUpload" :showUpload.sync="showUpload" :isOta="isOta"></global-upload>
    </el-container>
    <tos-dialog ref="TOSDialog" v-if="showTOS" @agreeTos="safetyTips"></tos-dialog>
    <user-info ref="userInfo" v-if="showUserInfo"></user-info>
    <user-info-add-or-update ref="userInfoAddOrUpdate" v-if="showUserInfoAddOrUpdate" @nextStep="getUerInfo"></user-info-add-or-update>
  </el-container>
</template>

<script>
import GlobalUpload from '@/components/GlobalUpload'
import menuIcon from '@/utils/menuIcon'
import TosDialog from '@/views/TosDialog.vue'
import UserInfo from '@/views/UserInfo.vue'
import UserInfoAddOrUpdate from '@/views/UserInfoAddOrUpdate.vue'
import { regPhone } from '@/utils'
export default {
  name: 'Home',
  components: { GlobalUpload, TosDialog, UserInfo, UserInfoAddOrUpdate },
  data() {
    return {
      showUpload: false,
      languageList: [
        { type: 'zh', label: '中文' },
        { type: 'en', label: 'English' }
      ],
      isOta: false,
      showTOS: false,
      showUserInfo: false,
      showUserInfoAddOrUpdate: false,
      isSafe: true,
      popoverVisible: false,
      dataForm: {
        username: ''
      },
      total: 100,
      used: 0,
      routers: []
    }
  },
  computed: {
    routes() {
      // return this.$store.state.routes;
      const routers = this.$router.options.routes
      return routers
    },
    // 获取当前路径
    path() {
      return ['/shootAndRule', '/myFile', '/analysis', '/analysisMore'].indexOf(this.$route.path) !== -1 ? '/myFolder' : this.$route.path
    },
    // 获取图标
    getIcon() {
      return (title, selFlag) => {
        let name = ''
        const iconObj = menuIcon.find((item) => {
          return item.name === title
        })
        name = iconObj ? iconObj.icon : 'shebeiguanli'
        return selFlag ? name + '_sel' : name
      }
    },
    menuStatus() {
      return (isAdmin) => {
        return isAdmin === window.isAdmin
      }
    },
    // 容量数值
    percentage() {
      return this.used >= this.total ? 100 : Number.parseFloat(((this.used / this.total) * 100).toFixed(1))
    },
    capacity() {
      return this.changeUnit(this.used) + ' / ' + this.changeUnit(this.total)
    },

    isAdmin() {
      return this.$store.state.isAdmin
    },
    globalCapacity() {
      return this.$store.state.globalCapacity
    }
  },
  watch: {
    globalCapacity() {
      // 查询容量
      this.getUserSpace()
    }
  },
  created() {
    this.routers = this.routes
    this.$store.commit('changeNeedMsg', true)
    this.getUerInfo()
    this.getUserSpace()
  },
  mounted() {
    // 监听页面刷新和关闭事件
    window.addEventListener('beforeunload', (e) => this.beforeUnload(e))
  },
  methods: {
    // 单位换算
    changeUnit(size) {
      let num = size
      let unit = 'KB'
      // MB
      if (num >= 1024) {
        num = Math.ceil(num / 1024)
        unit = 'MB'
        // GB
        if (num >= 1024) {
          num = Math.ceil((num * 10) / 1024) / 10
          if (num.toString().split('.')[1] === '0') {
            num = num.toString().split('.')[0]
          }
          unit = 'GB'
        }
      }

      return num + unit
    },
    // 查询容量
    getUserSpace() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/userSpace'),
        method: 'GET',
        params: this.$http.adornParams({
          uploadFileSize: 0
        })
      }).then((res) => {
        if (res.code === 0) {
          this.used = (res.data || {}).fileSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 当前用户信息
    getUerInfo() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/profile/current'),
        method: 'GET',
        params: this.$http.adornParams()
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data || {}
          const { admin, loginName, agreed, email, phonenumber, capacity, permission } = data
          this.total = capacity
          window.isAdmin = admin
          this.$store.commit('initAdmin', admin)
          this.isOta = admin
          this.dataForm = {
            email: email,
            emailChange: email ? email.substr(0, 3) + '****@' + email.split('@')[email.split('@').length - 1] : '',
            username: loginName,
            phone: phonenumber,
            phoneChange: phonenumber ? phonenumber.substr(0, 3) + '****' + phonenumber.substr(7) : ''
          }
          this.isSafe = email || (phonenumber && regPhone(phonenumber))
          sessionStorage.galdym = JSON.stringify(admin)

          // 权限
          const list = []
          for (let i = 0; i < 4; i++) {
            if ((permission >> i) & 1) {
              list.push(Math.pow(2, i))
            }
          }
          this.$store.commit('initAuth', list)
          this.routers.forEach((item) => {
            const children = item.children || []
            for (let j = 0; j < children.length; j++) {
              if ([8].includes(children[j].val)) {
                children[j].hidden = !list.includes(children[j].val)
              }
            }
          })

          if (this.path === '/') {
            this.$router.push('/myFolder')
          }

          // 验证服务协议
          if (!agreed) {
            this.showTOS = true
            this.$nextTick(() => {
              this.$refs.TOSDialog.init()
            })
          } else {
            // 验证邮箱手机信息
            this.safetyTips()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 改密码
    toChangePassword() {
      this.popoverVisible = false
      this.showUserInfoAddOrUpdate = true
      this.$nextTick(() => {
        const { emailChange, phoneChange } = this.dataForm
        this.$refs.userInfoAddOrUpdate.init({ type: emailChange && phoneChange ? 7 : emailChange ? 8 : 9, data: this.dataForm })
      })
    },
    // 账号管理
    toUserInfo() {
      this.showUserInfo = true
      this.$nextTick(() => {
        this.$refs.userInfo.init()
      })
    },
    // 安全提示
    safetyTips() {
      if (!this.isSafe) {
        this.$alert(
          `<div > 
            <div class="tmc_confirm_icon"></div> 
            <div class="tmc_confirm_title">安全提示</div>
            <div class="tmc_confirm_content">您的账号存在安全风险，请立即绑定邮箱</div> 
          </div>`,
          {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true,
            showClose: false,
            center: true,
            callback: () => {
              this.showUserInfoAddOrUpdate = true
              this.$nextTick(() => {
                this.$refs.userInfoAddOrUpdate.init({ type: 1, canClose: false })
              })
            }
          }
        )
      } else {
        this.changePasswordTips()
      }
    },
    // 是否需要修改密码
    changePasswordTips() {
      this.popoverVisible = sessionStorage.needTip === 'true'
    },
    langChange(type) {
      this.$i18n.locale = type
    },
    // 条款与条件
    toTOS() {
      const { href } = this.$router.resolve({ name: 'TermsOfService', query: {} })
      window.open(href, '_blank')
    },
    // 登出
    signOut() {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">${
            this.showUpload && !this.$refs.globalUpload.isFinish ? '列表中有未完成的上传任务，确定放弃上传吗?' : '确定退出么?'
          }</div> </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$cookie.delete('token')
          this.$router.replace('/login')
          this.$message({
            type: 'success',
            message: '退出成功'
          })
        })
        .catch(() => {})
    },
    // beforeunload
    beforeUnload(e) {
      if (this.showUpload && !this.$refs.globalUpload.isFinish) {
        // 兼容IE8和Firefox 4之前的版本
        e = e || window.event
        if (e) {
          e.returnValue = '关闭提示'
        }
        return '关闭提示'
      }
    },
    // 存储空间颜色
    colorMethod(percentage) {
      if (percentage < 80) {
        return '#5053dd'
      } else {
        return '#f56c6c'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  padding: 0 20px 0 0;
}
.header {
  background: #fff;
  color: #3a3b3e;
  box-sizing: border-box;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header_left {
    display: flex;
    display: -webkit-flex; /* Safari */
    align-items: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    div:nth-of-type(1) {
      margin: 0 8px 0 17px;
      img {
        vertical-align: middle;
      }
    }
  }
  .header_right {
    display: flex;
    display: -webkit-flex; /* Safari */
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
}
.safe-popover {
  padding: 12px;
  .popover-footer {
    margin-top: 24px;
    text-align: right;
  }
}
.popover-content {
  cursor: pointer;
}
.popover-content:hover {
  color: #a8a9ee;
}
.popover-content + .popover-content {
  margin-top: 10px;
}

.outContainer {
  height: calc(100% - 60px);
  .aside {
    background: #fff;
    width: 216px;
    overflow-x: hidden;
    #aside-menu {
      height: 90%;
      overflow: auto;
      .el-menu {
        border: none;
      }
      .menu_parent {
        width: 100%;
        color: #4d4e52;
        background: #fff;
        :hover {
          background: linear-gradient(270deg, rgb(0, 236, 255, 0.1) 0%, rgb(30, 30, 242, 0.1) 100%);
        }
        span {
          margin-left: 15px;
        }
        .menu_child {
          background: #fff;
          padding: 0 !important;
          .menu-item {
            padding: 0 20px;
            pointer-events: none;
            span {
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }
    #store-capacity {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 4px 10px 0px rgba(168, 171, 179, 0.29);
      border-radius: 6px;
      text-align: center;
      color: #fff;
      font-size: 0.5rem;
      overflow: hidden;
      #capacity-title {
        margin: 0.5rem 0;
        color: #3a3b3e;
      }
      #capacity-text {
        margin: 0.5rem 0;
        color: #3a3b3e;
        display: flex;
        justify-content: space-around;
        overflow: hidden;
      }
    }
  }
}
</style>
